import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import CtaBanner from "../../components/cta-banner";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import CtaBannerNew from "../../components/cta-banner-new";
import HeroEnd from "../../components/hero-end";

const BusinessCoachingNewYorkPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "Jason New York Hero.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				claire: file(
					relativePath: { eq: "testimonials/Claire freemen New.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				liz: file(relativePath: { eq: "testimonials/elizabeth-keates.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andres: file(relativePath: { eq: "testimonials/Andrew-Fenton.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Business Coaching New York";
	const description =
		"Are you a business owner or executive in New York City looking to take your business to the next level? Have you considered working with an experienced London-based business coach who can offer fresh thinking from across the pond?.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const claire = data.claire.childImageSharp.gatsbyImageData;
	const liz = data.liz.childImageSharp.gatsbyImageData;
	const andres = data.andres.childImageSharp.gatsbyImageData;

	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Business Coaching New York",
				item: `${siteUrl}/business-coaching-new-york`,
			},
		],
	};

	return (
		<Layout NY>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/business-coaching-new-york`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Business Coaching New York",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title={
					<>
						Business Coaching
						<br className="d-none d-lg-block" /> New York
					</>
				}
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Expand Your Horizons with a British Business Coach
							</h2>
							<p>
								Are you a business owner or executive in New York City looking
								to take your business to the next level? Have you considered
								working with an experienced London-based business coach who can
								offer fresh thinking from across the pond?
							</p>
							<h2 className="text-primary pb-3">
								Get a Global Perspective on How to Grow Your Business
							</h2>
							<p>
								Unlock your New York business's potential with my expert
								guidance. I am an experienced British business coach and can
								offer a global perspective, diverse expertise, and help you
								unlock a world of opportunities.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<ReactPlayer
								title="Jason Cornes"
								controls
								className="w-100 h-auto"
								url="https://jason-cornes.rjmdigital.net/wp-content/uploads/2023/09/Jason-USA.mp4"
							/>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5 ">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why Choose a British Business Coach?</h2>
							<h3 className="pb-3">Global Perspective</h3>
							<h4 className="hind-light">
								Experience the benefits of a British business coach with an
								international background
							</h4>
							<p>
								As a British business coach, I bring a global perspective,
								having worked across different markets and cultures for nearly
								40 years. By working with me you will benefit from valuable
								insights and expand your horizons beyond the New York market.
							</p>
							<h4 className="hind-light mt-3">Diverse Skillset</h4>
							<p>
								As a serial entrepreneur with decades of experience in running
								various businesses, I possess a wide range of business skills
								and expertise. From leadership to communication, strategy to
								problem-solving, you too can now tap into my vast knowledge and
								experience.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/Jason New York.png"
							/>
							<h3 className="text-primary pb-3">Innovative Solutions</h3>
							<h4 className="text-primary hind-light">
								Embrace fresh approaches to business challenges
							</h4>
							<p>
								Having helped many business owners overcome common issues, I
								offer innovative solutions and creative problem-solving
								techniques that can help you overcome the obstacles you face and
								that are preventing you from moving forward.
							</p>
							<h4 className="text-primary hind-light">
								Enhanced Communication
							</h4>
							<p>
								Fortunately, language is no barrier to working with me. As a
								British business coach based in the vibrant, cosmopolitan city
								of London which, like New York City, is buzzing with
								businesspeople from a wide range of backgrounds and cultures, I
								can help you enhance your communication skills and effectiveness
								and improve your interactions with your team and clients.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-0">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary hind-light pb-3">
								Ready to Take Your New York Business Global?
							</h3>
							<p>
								As your business coach, I will be fully committed to your
								success. Through personalized online coaching sessions that fit
								in with your schedule and New York business hours, I can help
								you set clear goals, establish a vision, hold you accountable
								for your progress toward achieving them, and provide valuable
								feedback.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-secondary text-white py-5 ">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Ready to work with me?</h3>
							<p>
								Contact me today to take the first step to finding more business
								and taking the fast lane to more profits!
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<p className=" hind-bold pb-3">
								Elevate your New York business to new heights by working with a
								British business coach that gets tangible results for continuous
								improvement and long-lasting success.
							</p>
							<p>
								Imagine the impact more profits and sales would have on your
								life!
							</p>
							<p>
								I’ve helped multiple clients create the lifestyle they want by
								working less and earning more. Unlock your New York business's
								global potential today and take the first step towards a
								brighter future.
							</p>

							<p>
								I can’t wait to talk to you and explore the many possibilities
								that await your business with me as your trusted British
								business coach by your side.
							</p>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="py-5">
				<Container>
					<Row>
						<Col className="text-center">
							<h2 className="pb-3 hind-light">
								Don’t just take my word for it, here’s what some of my many
								satisfied customers said about working with me.
							</h2>
						</Col>
					</Row>
					<HorizontalTestimonial
						name="Claire Freeman. Consultant Podiatric Surgeon FRCPodS"
						review="How do you choose a business coach? When you know you are stuck, and need help, how do you know who to trust with something as personal and critical as your business? I did a lot of searching and spoke to many different coaches, but when I spoke to Jason I immediately recognised his skill and professionalism. His background, experience and positive attitude have helped me in ways I did not expect. I've learnt so much about myself, the way I run my business and how to effect change. Would I choose him again - absolutely, would I recommend him - absolutely, is my business better off - absolutely, am I still stuck...no."
						profilePic={claire}
						linkedIn="https://www.linkedin.com/in/claire-freeman-frcpods-consultant/"
					/>

					<HorizontalTestimonial
						name="Elizabeth Keates Film & Photography"
						review="Astonishingly brilliant! Two rather powerful words to describe how I felt about Jason Cornes after our meeting. To cut to the chase, which is exactly what Jason did with a business growth issue I had been stuck with for quite a while, I found the session inspirational and I finally have a road map forward. Through hard-hitting truths Jason was able to show me exactly where I was stuck and why. He guided me towards a clear plan of action and together we identified the areas where I feel I need support to enable me to achieve the goal I have been trying to reach for a number of years now. Moments felt productively uncomfortable as we addressed my comfort zone and looked at my self-limiting and destructive cycles, but before my heart rate became too high, Jason quickly showed me ways to reach a solution and above all he has laid a plan to give me the support I need to let go and actually move forward. With Jason coaching from the side-line, I truly can’t wait to report back on where my business will be in 12 months."
						profilePic={liz}
						linkedIn="https://www.linkedin.com/in/elizabeth-keates-film-photography"
					/>

					<HorizontalTestimonial
						name="Andrew Fenton. Chartered Tax Advisor"
						review="I have been working with Jason for several months and I have found his support and ideas invaluable to me. His suggestions and mentoring have produced instant results and I am looking forward to continuing working with him. I would highly recommend Jason to anyone looking for someone to support their business and provide genuine added value."
						profilePic={andres}
						linkedIn="https://www.linkedin.com/in/andrew-fenton-25b54414"
					/>
				</Container>
			</section>
			<CtaBannerNew
				headline="What are you waiting for?"
				text="Book your free discovery call today!"
				btnTxt="Book now"
				btnLink="/contact-us"
				bgColor="light"
			/>
		</Layout>
	);
};
export default BusinessCoachingNewYorkPage;
